import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { FAQQuestion } from "../models/FAQQuestion";
import { CategoryDisplay } from "../components/CategoryDisplay";
import { MostPopularDisplay } from "../components/MostPopularDisplay";
function GatherCategories(FaqItems: FAQQuestion[]): string[] {
  let storedCategories: string[] = [];
  FaqItems.forEach((element) => {
    if (
      !Number(element.category) &&
      element.category !== "0" &&
      storedCategories.some((str) => element.category === str) === false
    ) {
      storedCategories.push(element.category);
    }
  });
  return storedCategories;
}

type MyState = {
  storedCategories: string[];
  FaqItems: FAQQuestion[];
  Loaded: boolean;
  Error: boolean;
  search: string;
  index: number;
};
type MyProps = {
  search: string;
  ChangeAnswerID: any
  UpdateFaqItems: any
};
export class BoxDisplay extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      Loaded: false,
      Error: false,
      storedCategories: [],
      FaqItems: [],
      search: this.props.search,
      index: 0,
    };
    // eslint-disable-next-line no-func-assign
  }

  componentDidMount() {
    fetch("https://api.pandahut.net/api/Help/Questions")
      .then((res) => res.json())
      .then(
        (result: FAQQuestion[]) => {
          this.setState({
            Loaded: true,
            FaqItems: result,
            storedCategories: GatherCategories(result),
          });
          this.props.UpdateFaqItems(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            Error: true,
          });
        }
      );
  }

  UpdateTest(input: string) {
    this.setState({ search: input, index: this.state.index + 1 });
  }
  render() {
    const { Loaded, Error, storedCategories, search } = this.state;
    let { FaqItems, index } = this.state;
    let TotalItems = FaqItems.length;
    FaqItems = FaqItems.filter(
      (item) =>
        search === "" ||
        item.answer.toLowerCase().includes(search.toLowerCase()) ||
        item.question.toLowerCase().includes(search.toLowerCase())
    );
    if (Error) return <p> Error </p>;
    else if (Loaded === false) return <p> Loading.... </p>;
    return (
      <div>
        <MostPopularDisplay FaqItems={FaqItems}></MostPopularDisplay>
        <Card className="MainCard">
          <CardContent>
            <p className="CenterText">
              Frequently Asked Questions{" "}
              {search !== ""
                ? `- (Filtered: ${TotalItems - FaqItems.length}/${TotalItems})`
                : ""}{" "}
            </p>
            {storedCategories.map((cat, i) => {
              return (
                <CategoryDisplay
                  ChangeAnswerID={this.props.ChangeAnswerID}
                  cat={cat}
                  questions={FaqItems.filter(
                    (item) => item.category.toLowerCase() === cat.toLowerCase()
                  )}
                  key={i + index}
                  open={search !== ""}
                ></CategoryDisplay>
              );
            })}
          </CardContent>
        </Card>
      </div>
    );
  }
}
export default BoxDisplay;
