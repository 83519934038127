import React from "react";
import { createStyles, fade, withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import PropTypes from "prop-types";

const useStyles = createStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));


/*class NavBar extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.OnSearch = this.OnSearch.bind(this);

    this.state = {
      search: "",
    };
  }

  OnSearch(e: any) {
    //OnFire
    this.props.OnSearchChange(e.target.value);
  }

  render() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <a href="https://pandahut.net" className={classes.title}>
              <img
                src="https://pandahut.net/wp-content/uploads/2015/12/logo_p_h.png"
                alt="PH Icon"
              ></img>
            </a>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={this.OnSearch}
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
*/

function NarBarComp(props: any) {
  const { classes, OnSearchChange } = props;

  const OnSearch = (event: any) => OnSearchChange(event.target.value);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <a href="https://pandahut.net" className={classes.title}>
            <img
              src="https://pandahut.net/wp-content/uploads/2015/12/logo_p_h.png"
              alt="PH Icon"
            ></img>
          </a>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={OnSearch}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

NarBarComp.propTypes = {
  classes: PropTypes.object.isRequired,
  search: PropTypes.any.isRequired,
  OnSearchChange: PropTypes.any.isRequired,
};

export default withStyles(useStyles)(NarBarComp);
