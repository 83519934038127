import React from "react";
import { FAQQuestion } from "../models/FAQQuestion";
import { Collapsible } from "./Collapsible";
const ReactMarkdown = require("react-markdown");
type MyProps = { questions: FAQQuestion[]; cat: string; open: boolean; ChangeAnswerID: any };
export class CategoryDisplay extends React.Component<MyProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: MyProps) {
    super(props);
  }

  render() {
    return (
      <div id={this.props.cat}>
        <Collapsible
          ChangeAnswerID={this.props.ChangeAnswerID}
          title={this.props.cat}
          className="Category"
          key={this.props.cat}
          open={this.props.open}
        >
          <div className="Answer">
            {this.props.questions.map((answer, i) => {
              return (
                <div id={answer.question} key={answer._id}>
                  <Collapsible
                    ChangeAnswerID={this.props.ChangeAnswerID}
                    title={answer.question}
                    className="Question"
                    key={answer._id}
                    open={false}
                    AnswerID={answer._id}
                    AnswerQuestion={answer.question}
                  >
                    <div className="Answer">
                      <p>
                        <ReactMarkdown
                          source={answer.answer}
                          escapeHtml={false}
                        ></ReactMarkdown>
                      </p>
                    </div>
                  </Collapsible>
                </div>
              );
            })}
          </div>
        </Collapsible>
      </div>
    );
  }
}
export default CategoryDisplay;
