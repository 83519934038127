import React from "react";
import { FAQQuestion } from "../models/FAQQuestion";
import { Card, CardContent, CardHeader } from "@material-ui/core";
const ReactMarkdown = require("react-markdown");
type MyState = {
  FeaturedItems: FAQQuestion[];
  Loaded: boolean;
  Error: boolean;
};
type MyProps = { FaqItems: FAQQuestion[] };
export class MostPopularDisplay extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      Loaded: false,
      Error: false,
      FeaturedItems: [],
    };
  }

  componentDidMount() {
    this.setState({
      FeaturedItems: this.props.FaqItems,
      Loaded: true,
    });
  }

  render() {
    const { Loaded, Error } = this.state;
    let FeaturedFirst = this.state.FeaturedItems.find(
      (item) => item._id === "5f21c14d557aa53950dcbfe0"
    );
    if (FeaturedFirst === undefined) {
      FeaturedFirst = this.state.FeaturedItems[1];
    }

    let FeaturedSecond = this.state.FeaturedItems.find(
      (item) => item._id === "5f21b412557aa53950dcbfdd"
    );
    if (FeaturedSecond === undefined) {
      FeaturedSecond = this.state.FeaturedItems[2];
    }
    if (Error) return null;
    else if (Loaded === false) return null;
    return (
      <div className="MostPopular">
        <Card className="MostPopularCard">
          <CardHeader title={this.state.FeaturedItems[0].question}></CardHeader>
          <CardContent>
            <ReactMarkdown
              source={this.state.FeaturedItems[0].answer}
              escapeHtml={false}
            ></ReactMarkdown>
          </CardContent>
        </Card>
        <Card className="MostPopularCard">
          <CardHeader title={FeaturedFirst.question}></CardHeader>
          <CardContent>
            <ReactMarkdown source={FeaturedFirst.answer}  escapeHtml={false}></ReactMarkdown>
          </CardContent>
        </Card>
        <Card className="MostPopularCard">
          <CardHeader title={FeaturedSecond.question} ></CardHeader>
          <CardContent>
            <ReactMarkdown source={FeaturedSecond.answer}  escapeHtml={false}></ReactMarkdown>
          </CardContent>
        </Card>
      </div>
    );
  }
}
export default MostPopularDisplay;
