import React from "react";
import logo from '../close.png' // relative path to image 

import { FAQQuestion } from "../models/FAQQuestion";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
const ReactMarkdown = require("react-markdown");

type MyState = { CardRef: any};
type MyProps = { Question: FAQQuestion | undefined, UpdateAnswerID: any};
export class ShowAnswerDetails extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    console.log(`Show Answer Details init for ${this.props.Question?._id}`)
    this.HideAnswer = this.HideAnswer.bind(this);
    this.state = { CardRef: React.createRef()}
  }
  componentDidMount() {
      if (this.state.CardRef.current) {
        this.state.CardRef.current.scrollIntoView({ 
            behavior: "smooth", 
            block: "nearest"
         })
      }
  }
  HideAnswer(e: any) {
    e.preventDefault();
    e.stopPropagation();
    window.history.pushState({}, '', "/");
    this.props.UpdateAnswerID(undefined)
  }
  GenerateTitle() {
      return (
          <div>
              <Typography>
              {this.props.Question !== undefined ? this.props.Question.question : ""}
              </Typography>
        <a href={`/`} className="FocusOnAnswer" onClick={(e) => this.HideAnswer(e)}><img src={logo} alt="Hide Answer" className="MaxSizeImage"></img></a> 
        </div>
      )
  }
  render() {
    return (
        <Card className="AnswerDetails" ref={this.state.CardRef}>
        <CardHeader title={this.GenerateTitle()}></CardHeader>
        <CardContent>
          <ReactMarkdown
            source={this.props.Question !== undefined ?this.props.Question.answer : "This question was removed. Please select another."}
            escapeHtml={false}
          ></ReactMarkdown>
        </CardContent>
      </Card>
    );
  }
}
export default ShowAnswerDetails;
