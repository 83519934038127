import React, { Component } from "react";
import NavBar from "./components/NavBar";
import BoxDisplay from "./components/BoxDisplay";
import { FAQQuestion } from "./models/FAQQuestion";
import ShowAnswerDetails from "./components/ShowAnswerDetails";
type MyState = {
  search: string;
  _child: React.RefObject<BoxDisplay>;
  ShowAnswerID: string | undefined;
  FaqItems: FAQQuestion[];
  LoadedAnswerID: string | undefined;
};
type MyProps = {};
class App extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    //this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = { search: "", _child: React.createRef(), ShowAnswerID: undefined, FaqItems: [], LoadedAnswerID: window.location.pathname.split('/')[1]}; // This won't throw error because always start with slash
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.ChangeAnswerID = this.ChangeAnswerID.bind(this)
    this.UpdateFaqItems = this.UpdateFaqItems.bind(this);
  }
  handleSearchChange(event: string) {
    this.setState({ search: event });
    if (this.state._child.current != null)
      this.state._child.current.UpdateTest(event);
  }
  ChangeAnswerID(newID: string) {
    console.log(`New Answer ID: ${newID}`)
    console.log(`We have ${this.state.FaqItems.length} Faq Items`)
    this.setState({ ShowAnswerID: newID})
  }
  UpdateFaqItems(FAQQuestions: FAQQuestion[]) {
    console.log(`Got new ${FAQQuestions.length} Faq Items`)
    
    this.setState({FaqItems: FAQQuestions})
    console.log(`Got LoadedAnswerID with ${this.state.LoadedAnswerID}`)
    if (this.state.LoadedAnswerID !== undefined && this.state.LoadedAnswerID !== ""){
      var faqquestion = this.state.FaqItems.find(x => this.state.LoadedAnswerID?.toLowerCase()?.includes(x._id.toLowerCase()))
      if (faqquestion === undefined)
      {
        console.log("Couldn't find question")
        this.setState({LoadedAnswerID: undefined})
      }
        else {
          this.setState({ShowAnswerID: faqquestion._id})
        }
    }
  }

  render() {
    const search = this.state.search;
    return (
      <div>
        <NavBar search={search} OnSearchChange={this.handleSearchChange}/>
        <div className="App">
          {this.state.ShowAnswerID !== undefined ? <ShowAnswerDetails Question={this.state.FaqItems.find(x => x._id === this.state.ShowAnswerID)} UpdateAnswerID={this.ChangeAnswerID}  /> : null}
          <div className={this.state.ShowAnswerID !== undefined ? "Blur": "MainApp"} >
          <BoxDisplay search={search} ChangeAnswerID={this.ChangeAnswerID} ref={this.state._child} UpdateFaqItems={this.UpdateFaqItems}/>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
