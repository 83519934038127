import React from "react";


type MyState = { open: boolean };
type MyProps = { title: string; className: string; open: boolean; AnswerID?: string; ChangeAnswerID:any; AnswerQuestion?: string };
export class Collapsible extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      open: this.props.open,
    };
    this.togglePanel = this.togglePanel.bind(this);
    this.ShowFullAnswer = this.ShowFullAnswer.bind(this);
  }
  togglePanel(e: any) {
    this.setState({ open: !this.state.open });
  }
  ShowFullAnswer(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.AnswerID !== undefined && this.props.AnswerQuestion !== undefined)
    {
    window.history.pushState({}, '', `${this.props.AnswerID}/${this.props.AnswerQuestion?.replaceAll(" ", "-")}`);
    }
    console.log("Now we moved!")
    this.props.ChangeAnswerID(this.props.AnswerID)
  }
  render() {
    return (
      <div className={this.props.className}>
        <div onClick={(e) => this.props.AnswerID != null ? this.ShowFullAnswer(e) : this.togglePanel(e)} className="header">
          {this.props.title}
         
        </div>
        {this.state.open ? (
          <div className="content">{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}
export default Collapsible;
